import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import Card from "~/components/styled/card"
import Image from "~/components/image"

const MemeList = ({ memes, gridCols }) => {
  return (
    <div className={`grid ${gridCols} gap-6`}>
      {memes.map(meme => {
        return (
          <Card key={meme.id}>
            <Link to={`/memes/${meme.slug}`} key={meme.id}>
              <Image
                alt="Meme Image"
                className="rounded-t-md border-gray-200	 border-b"
                image={meme.image}
              />
              <div className="px-4 py-6">
                <p>{meme.title}</p>
              </div>
            </Link>
          </Card>
        )
      })}
    </div>
  )
}

MemeList.propTypes = {
  memes: PropTypes.array,
  gridCols: PropTypes.string,
}

MemeList.defaultProps = {
  gridCols: "grid-cols-1 md:grid-cols-3",
}

export default MemeList
